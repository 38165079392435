import React, {Component} from "react"
class Contact extends Component {
    render () {
        return(
            <h1>Dre's Contact Me</h1>
        )
    
    
    
    
    }





}
 export default Contact