import React, {Component} from "react"
class About extends Component {
    render () {
        return(
            <h1>Dre's About me</h1>
        )
    
    
    
    
    }





}
 export default About